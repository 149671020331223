<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";

import { ChevronDownIcon } from "vue-feather-icons";

export default {
  components: {
    ChevronDownIcon,
  },
  extends: BaseVue,
  data() {
    return {
      heroImage: {},
      static1_desc: "",
      static1_youtube: "",
      static1_title: "",
      static1_postTitle: "",
      visi: {},
      misi: {},
      tujuan: {},
      statistik: [],
      struktur: {},
      guru: [],
      staff: [],
      showVisiMisi: "",
    };
  },
  computed: {},
  mounted() {
    setTimeout(()=>{
      $(".short-about__desc p a").addClass("linkWhite")
    },500)
    this.$set(this.$root, "page", this);
    this.getAbout();
    setTimeout(() => {
      SEMICOLON.widget.accordions();
      SEMICOLON.widget.carousel();
    }, 800);
  },
  methods: {
    getAbout() {
      Gen.apirest("/about", {}, (err, resp) => {
        if (err) console.log("About goodness error " + err);
        this.heroImage = resp.heroImage;
        this.static1_title = resp.static1_title;
        this.static1_postTitle = resp.static1_postTitle;
        this.static1_desc = resp.static1_desc;
        this.static1_youtube = resp.static1_youtube;
        this.visi = resp.visi;
        this.misi = resp.misi;
        this.tujuan = resp.tujuan;
        this.statistik = resp.statistik;
        this.struktur = resp.struktur;
        this.guru = resp.guru;
        this.staff = resp.staff;
        this.showVisiMisi = resp.showVisiMisi;
      });
    },
    offset(el) {
      var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    },
    youtubeLink(link){
      let yt = link.split(/[/=]+/)
      let sub = yt[yt.length - 1]
      let newLink =  'https://www.youtube.com/embed/' + sub
      return newLink
    },
    stabilizeScroll(e) {
      setTimeout(() => {
        window.scroll({
          top: this.offset(e.target.closest('.vis-accord')).top - 280,
          behavior: 'smooth',
        })
      }, 200);
    }
  },
  watch: {},
};
</script>
<template>
  <div>
    <section
      v-if="!this.$root.isMobile"
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img)}')`"
    >
      <div class="container">
      <span class="d-none position-absolute">{{ heroImage.alt_img }}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">SDI Al Hidayah</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement" 
                  itemscope
                  itemtype="https://schema.org/ListItem" 
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >Tentang</li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  SDI Al Hidayah
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img_mobile)}')`"
    >
      <div class="container">
      <span class="d-none position-absolute">{{ heroImage.alt_img_mobile }}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">SDI Al Hidayah</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement" 
                  itemscope
                  itemtype="https://schema.org/ListItem" 
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >Tentang</li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  SDI Al Hidayah
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="content">
      <div class="content-wrap">
        <section class="section bg-color dark gap-cleaner-4 py-84">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-10 order-2 order-lg-0">
                <div class="short-about">
                  <div class="short-about__title">
                    <h2 itemprop="alternativeHeadline">{{ static1_title }}</h2>
                    <span itemprop="text" class="short-about__title__semi">{{
                      static1_postTitle
                    }}</span>
                  </div>
                  <div itemprop="description" class="short-about__desc" v-html="static1_desc" v-if="static1_postTitle"></div>
                  <div itemprop="description" class="short-about__desc desc__withoutpt" v-html="static1_desc" v-else></div>
                </div>
              </div>
              <div class="col-lg-6 col-md-10">
                <div class="about-video" itemscope itemtype="https://schema.org/VideoObject">
                  <iframe
                    width="560"
                    height="315"
                    :src="youtubeLink(static1_youtube)"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    itemprop="video"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="section green-wave bottom-highlight py-144"
          v-if="showVisiMisi == 'Y'"
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="accordion vision-accordions">
                  <div class="vis-accord">
                    <div class="accordion-header vis-accord-header" id="visi">
                      <div class="accordion-icon vis-accord__icon">
                        <chevron-down-icon></chevron-down-icon>
                      </div>
                      <div itemprop="name" class="accordion-title vis-accord__title">
                        Visi
                      </div>
                    </div>
                    <div
                      itemprop="description"
                      class="accordion-content vis-accord__content"
                      v-html="visi.desc"
                    ></div>
                  </div>
                  <div class="vis-accord">
                    <div class="accordion-header vis-accord-header" id="misi">
                      <div class="accordion-icon vis-accord__icon">
                        <chevron-down-icon></chevron-down-icon>
                      </div>
                      <div itemprop="name" class="accordion-title vis-accord__title">
                        Misi
                      </div>
                    </div>
                    <div
                      itemprop="description"
                      class="accordion-content vis-accord__content"
                      v-html="misi.desc"
                    ></div>
                  </div>
                  <div class="vis-accord">
                    <div class="accordion-header vis-accord-header" id="tujuan" @click="stabilizeScroll">
                      <div class="accordion-icon vis-accord__icon">
                        <chevron-down-icon></chevron-down-icon>
                      </div>
                      <div itemprop="name" class="accordion-title vis-accord__title">
                        Tujuan
                      </div>
                    </div>
                    <div
                      itemprop="description"
                      class="accordion-content vis-accord__content"
                      v-html="tujuan.desc"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section bg-color gap-cleaner py-24">
          <div class="container">
            <div class="row justify-content-center col-mb-48">
              <div
                class="col-lg-4 col-md-6"
                v-for="(item, index) in statistik"
                :key="index"
                :style="{ display: item.active == 'N' && 'none' }"
              >
                <div class="card-elem">
                  <div class="count">
                    <div :class="['count-circle', item.bg]">
                      <img
                        :src="uploader(item.img)"
                        :alt="item.alt_img"
                        :title="item.alt_img"
                        class="count-circle__img"
                        itemprop="image"
                      />
                    </div>
                    <div class="count-content">
                      <h3 itemprop="description" class="count-content__number count__about">{{ item.desc }}</h3>
                      <span itemprop="name" class="count-content__title">{{ item.title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
        <section v-if="struktur.active === 'Y'" class="section green-wave py-115">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Struktur Organisasi</h2>
            </div>
            <div
              class="row justify-content-center"
            >
              <div class="col-lg-10 col-md-11">
                <img itemprop="image" :src="uploader(struktur.img)" :alt="struktur.alt_img" :title="struktur.alt_img" />
              </div>
            </div>
          </div>
        </section>
        <section v-if="guru.length > 0" class="section py-35">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Tenaga Pengajar</h2>
            </div>
            <div
              class="owl-carousel carousel-widget staff-carousel"
              data-items-xs="1"
              data-items-md="2"
              data-items-lg="4"
              :data-loop="(guru||[]).length < 2 ? 'false' : 'true'"
              data-autoplay="5000"
              data-margin="48"
            >
              <div
                itemscope itemtype="https://schema.org/Person"
                class="staff-photo"
                v-for="(value, index) in guru"
                :key="index"
              >
                <img
                  :src="uploader(value.ai_img)"
                  :alt="value.ai_alt_img"
                  :title="value.ai_alt_img"
                  class="staff-photo__img"
                  itemprop="image"
                />
                <div class="staff-photo-meta">
                  <h3 itemprop="name" class="staff-photo__name">
                    {{ value.ai_name }}
                  </h3>
                  <span itemprop="jobTitle">{{ value.ai_description }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="staff.length > 0" class="section py-55t">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Staff</h2>
            </div>
            <div
              class="owl-carousel carousel-widget staff-carousel"
              data-items-xs="1"
              data-items-md="2"
              data-items-lg="4"
              :data-loop="staff.length < 2 ? 'false' : 'true'"
              data-autoplay="5000"
              data-margin="48"
            >
              <div
                itemscope itemtype="https://schema.org/Person"
                class="staff-photo"
                v-for="(value, index) in staff"
                :key="index"
              >
                <img
                  :src="uploader(value.ai_img)"
                  :alt="value.ai_alt_img"
                  :title="value.ai_alt_img"
                  class="staff-photo__img"
                  itemprop="image"
                />
                <div class="staff-photo-meta">
                  <h3 itemprop="name" class="staff-photo__name">
                    {{ value.ai_name }}
                  </h3>
                  <span itemprop="jobTitle">{{ value.ai_description }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>
